<footer class="footer">
    <div class="container">
        <div class="row row-grid align-items-center mb-5">
            <div class="col-lg-6"></div>
            <div class="col-lg-6 text-right btn-wrapper">
                <button target="_blank" href="#" rel="nofollow" class="btn-icon-only rounded-circle btn btn-facebook" data-toggle="tooltip" data-original-title="Like us">
                    <span class="btn-inner--icon"><i class="fab fa-facebook"></i></span>
                </button>
                <button target="_blank" href="#" rel="nofollow" class="btn btn-icon-only btn-twitter rounded-circle" data-toggle="tooltip" data-original-title="Follow us">
                    <span class="btn-inner--icon"><i class="fab fa-twitter"></i></span>
                </button>
            </div>
        </div>
        <hr>
        <div class="row align-items-center justify-content-md-between">
            <div class="col-md-6">
                <div class="copyright text-white">
                    &copy; 2021 Desarrollado por <a href="https://mycleth.com.mx/" target="_blank">MyCleth</a>.
                </div>
            </div>
        </div>
    </div>
</footer>