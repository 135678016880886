<div [ngClass]="['section']" id="contacto">
    <div class="container">
        <h2 class="mb-5"><span>Contactanos</span></h2>
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="map-responsive">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3762.5017823153653!2d-99.14283148537169!3d19.433920986883543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses!2smx!4v1617688767709!5m2!1ses!2smx" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen></iframe>
                </div>
                <div class="mt-4">
                    <p><i class="fas fa-map-marker-alt fa-2x"></i></p>
                    <p><i class="far fa-envelope fa-2x"></i></p>
                    <p><i class="fas fa-phone-alt fa-2x"></i></p>
                </div>
                
            </div>
            <div class="col-12 col-lg-6">
                <form method="POST">
                    <div class="form-group">
                        <input type="text" name="nombre" id="nombre" class="form-control" placeholder="Nombre Completo:">
                    </div>
                    <div class="form-group">
                        <input type="email" name="correo" id="correo" class="form-control" placeholder="Correo electrónico:">
                    </div>
                    <div class="form-group">
                        <input type="number" name="telefono" id="telefono" class="form-control" placeholder="Telefono:">
                    </div>
                    <div class="form-group">
                        <textarea name="mensaje" id="mensaje" cols="30" rows="5" class="form-control">Mensaje:</textarea>
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn btn-primary">Enviar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>