<app-menu></app-menu>
<div class="wrapper">
  <app-header></app-header>
  <app-nosotros></app-nosotros>
  <app-sectores></app-sectores>
  <app-productos></app-productos>
  <app-contacto></app-contacto>
  <app-bsellada></app-bsellada>
  <app-mnormal></app-mnormal>
  <app-mtermica></app-mtermica>
  <app-footer></app-footer>
</div>



