import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-bsellada',
  templateUrl: './bsellada.component.html',
  styleUrls: ['./bsellada.component.scss']
})

export class BselladaComponent implements OnInit {

  constructor() { }
 

  ngOnInit(): void {
  }

}
