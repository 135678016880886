<div class="section" id="nosotros">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="info info-horizontal info-hover-primary">
                    <div class="description text-justify">
                        <p>Somos Mallas Pack empresa mexicana dedicada a la fabricación de mallas
                            extruidas, contamos con una amplia gama de redes plásticas con diferentes
                            estructuras, medidas, pesos y colores que cubren todas las necesidades de
                            embalaje en la industria.
                        </p>
                        <p>
                            Nuestros productos son fabricados bajo los estándares más rigurosos de calidad,
                            asegurando siempre el mejor servicio y la entrega puntual de tu producto.
                        </p>
                        <p>
                            Nuestro compromiso es proveer soluciones de empaque a nuestros clientes a
                            través de la estandarización de nuestros procesos, contando con una estructura
                            organizacional que nos permita dar respuestas rápidas y lograr relaciones
                            comerciales a largo plazo con el cliente
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-10 mx-md-auto">
                <img class="ml-lg-5" src="./assets/img/hilos.png" width="100%">
            </div>
        </div>
    </div>
</div>