<div class="section text-center sectores-empaque">
    <div class="container">
        <div class="row">
            <div class="col-md-12 mx-auto text-center pb-10">
                <h3 class="display-3 text-white">Nuestros sectores de empaque</h3>
                <div class="info info-horizontal info-hover-primary">
                    <div class="description">
                        <p class="text-white">
                            En Mallas Pack encontraras redes plásticas que se adapten a tus necesidades,
                            contamos con dos presentaciones: bobina de mil metros y bolsa sellada, las
                            cuales no solo se emplean en frutas y verduras, sino también en juguetería,
                            promocionales, productos de exportación y más. Mallas pack la solución eficiente
                            para todo tipo de industria
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="info sectores">
                    <div class="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle">
                        <i class="fas fa-utensils"></i>
                    </div>
                    <h6 class="info-title text-uppercase text-white">Alimentaria</h6>
                </div>
            </div>
            <div class="col-md-3">
                <div class="info sectores">
                    <div class="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle">
                        <i class="fas fa-percentage"></i>
                    </div>
                    <h6 class="info-title text-uppercase text-white">Promocionales</h6>
                </div>
            </div>
            <div class="col-md-3">
                <div class="info sectores">
                    <div class="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle">
                        <i class="fas fa-gamepad"></i>
                    </div>
                    <h6 class="info-title text-uppercase text-white">Juguetes</h6>
                </div>
            </div>
            <div class="col-md-3">
                <div class="info sectores">
                    <div class="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle">
                        <i class="fas fa-globe-americas"></i>
                    </div>
                    <h6 class="info-title text-uppercase text-white">Empaque en general</h6>
                </div>
            </div>
        </div>
    </div>
</div>