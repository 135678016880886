import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MenuComponent } from './components/menu/menu.component';
import { NosotrosComponent } from './pages/nosotros/nosotros.component';
import { SectoresComponent } from './pages/sectores/sectores.component';
import { ProductosComponent } from './pages/productos/productos.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { BselladaComponent } from './modals/bsellada/bsellada.component';
import { MnormalComponent } from './modals/mnormal/mnormal.component';
import { MtermicaComponent } from './modals/mtermica/mtermica.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    NosotrosComponent,
    SectoresComponent,
    ProductosComponent,
    ContactoComponent,
    BselladaComponent,
    MnormalComponent,
    MtermicaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
