import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sectores',
  templateUrl: './sectores.component.html',
  styleUrls: ['./sectores.component.scss']
})
export class SectoresComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
