import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mnormal',
  templateUrl: './mnormal.component.html',
  styleUrls: ['./mnormal.component.scss']
})
export class MnormalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
