<div class="modal fade" id="bSellada" tabindex="-1" role="dialog" aria-labelledby="bSelladaLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="bSelladaLabel">Bolsa Sellada</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6">
                        <p class="text-justify">
                            Fabricación de Bolsa sellada es ideal para empacar y transportar productos como pelotas, canicas, 
                            fruta verdura, juguetes accesorios. Esta sellada térmicamente a base de calor por la parte inferior, 
                            se entrega en atados de 100pzs. Para ahorrar tiempo y facilitar el empaque. Se corta de la medida 
                            requerida en largos a partir de 30cm en adelante en múltiplos de 10 cm.
                        </p>
                        <p>Colores disponibles</p>
                        <div>
                            <button [ngClass]="['btn', 'verde']">x</button>
                            <button [ngClass]="['btn', 'amarillo']">x</button>
                            <button [ngClass]="['btn', 'azul']">x</button>
                            <button [ngClass]="['btn', 'naranja']">x</button>
                            <button [ngClass]="['btn', 'rojo']">x</button>
                            <button [ngClass]="['btn', 'natural']">s</button>
                        </div>
                    </div>
                    <div class="col-md-6">
                      <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                          <div class="carousel-item active">
                            <img class="d-block w-100" src="../../../assets/img/productos/pelotas.png" alt="First slide">
                          </div>
                          <div class="carousel-item">
                            <img class="d-block w-100" src="..." alt="Second slide">
                          </div>
                          <div class="carousel-item">
                            <img class="d-block w-100" src="..." alt="Third slide">
                          </div>
                        </div>
                        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="sr-only">Next</span>
                        </a>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary">Solicitar Cotizacion</button>
        </div>
      </div>
    </div>
</div>