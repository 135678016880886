<div class="section bienvenida section-shaped">
    <div class="shape shape-style-3 shape-default"></div>
    <div class="page-header">
        <div class="container shape-container d-flex align-items-center py-24">
            <div class="col px-0">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-6 text-center">
                        <h1 class="text-white">Bienvenido a MallasPack</h1>
                        <h2 class="text-white">Una malla para cada ocasión</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
        </svg>
    </div>
</div>