<div class="section" id="productos">
    <div class="container">
        <h2 class="mb-5"><span>Nuestros productos</span></h2>
        <div class="row text-center">
            <div class="col-sm-4 col-12 mb-5 mt-sm-0" data-toggle="modal" data-target="#bSellada">
                <a type="button" class="btn-tooltip" data-toggle="tooltip" data-placement="bottom" title="Sellada térmicamente a base de calor por la parte inferior" data-container="body" data-animation="true">
                    <img src="./assets/img/icon.png" alt="Bolsa sellada" class="img-fluid rounded shadow-lg" style="width: 250px;">
                </a>
                <small class="d-block text-uppercase font-weight-bold mb-4">Bolsa Sellada</small>
            </div>
            <div class="col-sm-4 col-12 mb-5 mt-sm-0" data-toggle="modal" data-target="#mNormal">
                <a type="button" class="btn-tooltip" data-toggle="tooltip" data-placement="bottom" title="Ideal para empacar promocionales, juguetes artículos para el hogar, deportes,  dulces, cosméticos y lácteos" data-container="body" data-animation="true">
                    <img src="./assets/img/icon.png" alt="Bolsa sellada" class="img-fluid rounded shadow-lg" style="width: 250px;">
                </a>
                <small class="d-block text-uppercase font-weight-bold mb-4">Malla normal</small>
            </div>
            <div class="col-sm-4 col-12 mb-5 mt-sm-0" data-toggle="modal" data-target="#mTermica">
                <a type="button" class="btn-tooltip" data-toggle="tooltip" data-placement="bottom" title="Para el cultivo de la ostra, empacar mejillones, conchas, camarones, papas. Para el congelamiento de productos en general." data-container="body" data-animation="true">
                    <img src="./assets/img/icon.png" alt="Bolsa sellada" class="img-fluid rounded shadow-lg" style="width: 250px;">
                </a>
                <small class="d-block text-uppercase font-weight-bold mb-4">Malla termica</small>
            </div>          
            <!-- <div class="col-sm-4 col-12 mb-5 mt-sm-0">
                <a type="button" class="btn-tooltip" data-toggle="tooltip" data-placement="bottom" title="Utilizado para el cierre de la malla de forma manual, puede ocuparse para empaques grandes como pequeños. " data-container="body" data-animation="true">
                    <img src="./assets/img/icon.png" alt="Bolsa sellada" class="img-fluid rounded shadow-lg" style="width: 250px;">
                </a>
                <small class="d-block text-uppercase font-weight-bold mb-4">Cinchos</small>
            </div> -->
        </div>
    </div>
</div>